// import external dependencies

// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_fontawesome.js'; import './autoload/_fontsource.js'

Joomla = window.Joomla || {};

(function(Joomla, document) {
  'use strict';

  document.addEventListener('DOMContentLoaded', function (event) {
    [].slice.call(document.head.querySelectorAll('link[rel="lazy-stylesheet"]'))
    .forEach(function($link){
      $link.rel = "stylesheet";
    });
  });

  jQuery(function($) {
    // Add click event handler to hamburger icon
    $('.hamburger').on('click' ,function() {
      $(this).toggleClass('is-active');
    });

    // Add home icon to default menu-item
    var menu = $('.header-menu .navbar-nav');
    var home = menu.children('.default').children('a');
    var homeHtml = home.html();

    homeHtml = '<i class="home-icon fas fa-lg fa-home"></i><span class="home-text">' + homeHtml + '</span>';
    home.html(homeHtml);

    // Add point right icon to Wat zoek je menu-items
    $('.home-watzoekje li a').prepend('<i class="fas fa-hand-point-right"></i>');
    $('.btn-info-contact').prepend('<i class="fas fa-hand-point-right"></i>');
    // Add angle right icon to btn-primary
    $('.btn-primary').append('<i class="fas fa-angle-right"></i>');

    $('#therapeuten_0').on('show.bs.collapse', function () {
      console.log('Show');
    });

    $('.tbl-jaargang').on('click', 'td', function() {
      $(this).addClass('selected').siblings().removeClass('selected');
      $('.calendar-item').eq($(this).index()).show(0).siblings('.calendar-item').hide(0);
    });

  });

})(Joomla, document);